<template>
	<v-list-tile v-if="document && document.updated_at">
		<v-list-tile-content>
			<v-list-tile-title>{{ $t('documents.last_update') }}</v-list-tile-title>
			<v-list-tile-sub-title>{{ $translateDateTime(document.updated_at, 'long') }}</v-list-tile-sub-title>
		</v-list-tile-content>
	</v-list-tile>
</template>

<script>
import DocumentsManagerModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentsManagerModuleGuard'

export default {
	name: 'DocumentUpdate',
	mixins: [DocumentsManagerModuleGuard],
	props: {
		value: {
			type: Object,
			required: false,
			default: null
		}
	},
	computed: {
		model: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		document: function () {
			return this.model
		}
	},
	methods: {}
}
</script>
